import * as React from "react"
import Layout from "../Layout"
import Card from "../components/Card"

const IndexPage = () => (
  <Layout
    title={"Welcome"}
    pageTitle={"Worksimply Docs"}
    description={
      "Explore our guides, resources, and references to build with Worksimply"
    }
    pageDescription={
      "Explore our guides, resources, and references to build with Worksimply"
    }
  >
    <div className="row">
      <div className="col-md-6 mt-5">
        <h2>Start Building</h2>
        <Card
          title={"Authentication"}
          description={"How to authenticate API requests"}
          link={"authentication"}
          linkLabel={"Learn More"}
        />
        <Card
          title={"API Reference"}
          description={"View the API endpoints and data structures"}
          link={"api-reference"}
          linkLabel={"Learn More"}
        />
      </div>
      <div className="col-md-6 mt-5">
        <h2>Learn about Worksimply</h2>
        <Card
          title={"Key Concepts"}
          description={"Key concepts to know before start using our API"}
          link={"key-concepts"}
          linkLabel={"Learn More"}
        />
        <Card
          title={"See it in Action"}
          description={
            "See how the API works in our live website - worksimply.com"
          }
          link={"https://worksimply.com/find-space?from=/"}
          linkLabel={"Learn More"}
          newTab
        />
      </div>
    </div>
  </Layout>
)

export default IndexPage
