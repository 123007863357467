import React from "react"
import * as s from "./index.module.less"
import { Link } from "gatsby"

const Card = ({
  title = "",
  description = "",
  link = "",
  linkLabel = "",
  newTab = false,
}) => {
  return (
    <div className={s.card}>
      <h3>{title}</h3>
      <p>{description}</p>
      {link && newTab ? (
        <a href={link} target={"_blank"} rel={"noreferrer"}>
          {linkLabel}
        </a>
      ) : (
        <Link to={`/${link}`}>{linkLabel}</Link>
      )}
    </div>
  )
}

export default Card
