import React, { useEffect, useState } from "react"
import Link from "gatsby-link"
import { AiOutlineHome } from "react-icons/ai"
import { GiBookmark } from "react-icons/gi"
import { VscLink } from "react-icons/vsc"
import { FaUserLock } from "react-icons/fa"
import { ImNewTab } from "react-icons/im"

const Menu = () => {
  const windowHref = typeof window !== "undefined" ? window.location.href : ""
  const [activeSubmenu, setActiveSubmenu] = useState(false)
  useEffect(() => {
    const pathname =
      typeof window !== "undefined" ? window.location.pathname : ""
    setActiveSubmenu(pathname === "/api-reference")
  }, [windowHref])
  return (
    <ul>
      <li>
        <Link to={"/"} activeClassName={"text-white"}>
          <AiOutlineHome /> Home
        </Link>
      </li>
      <li>
        <Link to={"/key-concepts"} activeClassName={"text-white"}>
          <GiBookmark /> Key Concepts
        </Link>
      </li>
      <li>
        <Link to={"/authentication"} activeClassName={"text-white"}>
          <FaUserLock />
          Authentication
        </Link>
      </li>
      <li>
        <Link to={"/api-reference"} activeClassName={"text-white"}>
          <VscLink />
          API Reference
        </Link>
        {activeSubmenu && (
          <ul>
            {anchors.map(({ link, label }, idx) => (
              <li key={idx}>
                <Link
                  to={`/api-reference#${link}`}
                  className={isActiveHash(link)}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>

      <li>
        <a
          href={"https://worksimply.com/find-space?from=/"}
          rel={"noreferrer"}
          target="_blank"
        >
          <ImNewTab />
          See it in Action
        </a>
      </li>
    </ul>
  )
}

export default Menu

const isActiveHash = hash => {
  const windowHash = typeof window !== "undefined" ? window.location.hash : ""

  return windowHash === `#${hash}` ? "" : ""
}

const anchors = [
  {
    link: "enterprises_create",
    label: "Create Enterprises",
  },
  {
    link: "enterprises_update",
    label: "Update Enterprises",
  },
  {
    link: "enterprises_list",
    label: "List Enterprises",
  },
  {
    link: "search_desks",
    label: "Search Desks",
  },
  {
    link: "search_on_demand_offices",
    label: "Search On-demand Offices",
  },
  {
    link: "location_view",
    label: "View Location",
  },
  {
    link: "filter_on_demand_offices",
    label: "Filter On-demand Offices",
  },
  {
    link: "reserve_desks",
    label: "Reserve Desks",
  },
  {
    link: "reserve_on_demand_offices",
    label: "Reserve On-demand Offices",
  },
  {
    link: "confirm_reservation_desks",
    label: "Confirm Desk Reservation",
  },
  {
    link: "confirm_reservation_on_demand_office",
    label: "Confirm On-demand Reservation",
  },
  {
    link: "cancel_reservation_desks",
    label: "Cancel Desk Reservation",
  },
  {
    link: "cancel_reservation_on_demand_office",
    label: "Cancel On-demand Office Reservation",
  },
]
