import React, { useState } from "react"
import * as s from "./index.module.less"
import Seo from "../components/seo"
import Link from "gatsby-link"
import Menu from "./Menu"

const Layout = ({
  title = "Welcome",
  description = "",
  children,
  pageTitle = "",
  pageDescription = "",
}) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <div className={s.wrapper}>
      <Seo title={title} description={description} />
      <aside className={mobileMenu ? s.show_mobile_menu : ""}>
        <div className={"text-right d-md-none"}>
          <button className="text-theme" onClick={() => setMobileMenu(false)}>
            Close
          </button>
        </div>
        <Link to={"/"}>
          <Logo />
        </Link>
        <Menu />
      </aside>
      <main>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-right">
              <a
                href="mailto:hello@worksimply.com"
                className={"font-weight-500"}
              >
                Support
              </a>
              <button
                onClick={() => setMobileMenu(!mobileMenu)}
                className={"text-theme font-weight-500 ml-3 d-md-none"}
              >
                Menu
              </button>
            </div>
          </div>

          <h1>{pageTitle || title}</h1>

          {pageDescription && <p>{pageDescription}</p>}

          {children}
        </div>
      </main>
    </div>
  )
}

export default Layout

const Logo = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <rect width="200" height="200" fill="#ECD295" />
      <g filter="url(#filter0_d)">
        <circle cx="100" cy="100" r="73" fill="white" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.565 87.375L138.29 124.502H132.686L121.478 87.375H131.565ZM68.4346 87.375L61.7097 124.502H67.3138L78.522 87.375H68.4346Z"
        fill="#222222"
      />
      <path
        d="M60 76.1071C60 75.4957 60.4957 75 61.1071 75H138.893C139.504 75 140 75.4957 140 76.1071V84.5006C140 85.1121 139.504 85.6077 138.893 85.6077H61.1071C60.4957 85.6077 60 85.1121 60 84.5006V76.1071Z"
        fill="#222222"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-18"
        y="22"
        width="196"
        height="196"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-20" dy="20" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
